import { useContext } from "react";
import { Typography, Button } from "@mui/material";
import React from "react";
import AttributeSelector from "./Forms/AttributeSelector";
import AnnonceContext from '../../../../contexts/AnnonceContext';

export default function Step4() {
 
  const { handleSubmit } = useContext(AnnonceContext);

  return (
    <>
      <Typography>
        Indiquez ici tout détail supplémentaire vous permettant d'authentifier le bon propriétaire (Ils ne seront visibles que par vous !)
      </Typography>

      <AttributeSelector isSecret={true}/>

      <br />

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Publiez votre annonce
      </Button>
    </>
  );
}
