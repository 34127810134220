import { Button } from "@mui/material";
import React, { useContext } from "react";
import ImageUploading from "react-images-uploading";
import AnnonceContext from "../../../../../contexts/AnnonceContext";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

export default function Upload() {
  const [images, setImages] = React.useState([]);
  const { setImageUpload } = useContext(AnnonceContext);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setImageUpload(imageList[0] ? imageList[0].file : null);
  };
  return (
    <div>
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <Button
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              <AddAPhotoIcon sx={{ fontSize: 200, color: "#60dafb" }} />
            </Button>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.data_url} alt="" width="200" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}>Update</button>
                  <button onClick={() => onImageRemove(index)}>
                    Supprimer la photo
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
