import {useState, useEffect} from "react"
// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CardNews3 } from "src/components/EventCards/EventCard";

export default function Profil() {
    const navigate = useNavigate();

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [nom, setNom] = useState("")
    const [adresse, setAdresse] = useState("")
    const [ville, setVille] = useState("")
    const [postal, setPostal] = useState("")
    const [pays, setPays] = useState("")

    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        axios.get(process.env.REACT_APP_PUBLIC_API + '/me').then(response => {
            console.log(response)
            setName(response.data.firstname)
            setEmail(response.data.email)
            setNom(response.data.lastname)
            setAdresse(response.data.street)
            setVille(response.data.ville)
            setPostal(response.data.cp)
            setPays(response.data.country)
        })
    }, [])

    function updateUser(user) {
      return axios.patch(process.env.REACT_APP_PUBLIC_API + '/me', user)
    }

    const handleUserUpdate = async () => {
      setIsSubmitting(true)

      const body = {
        firstname: name?.trim(),
        lastname: nom?.trim(),
        lieudit: "",
        batiment: "",
        apptEtage: "",
        street: adresse?.trim(),
        cp: postal?.trim(),
        ville: ville?.trim(),
        country: pays?.trim(),
        email: email?.trim(),
    }

      updateUser(body).then((res) => {
          if (res && res.status === 200)
            console.log('ok')
            navigate(0);
      })
    }

  return (
    <Page title="Profil">
        <h1>Bonjour {name},</h1>
        <br />
        <div className="evenement">
          <CardNews3 />
        </div>

        <h2>Mon profil</h2>
        <br />
        <TextField type="text" label="Nom" 
          style={{margin:"20px"}}
          name="nom"
          id="nom"
          onChange={(e) => setNom(e.target.value)}
          value={nom}
        />
        
        <TextField type="text" label="Prénom" 
          style={{margin:"20px"}}
          name="prenom"
          id="prenom"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />

        <TextField fullWidth type="email" label="Email" 
          style={{margin:"20px"}}
          name="email"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <TextField type="text" label="Adresse" 
          style={{margin:"20px"}}
          name="adresse"
          id="adresse"
          onChange={(e) => setAdresse(e.target.value)}
          value={adresse}
        />

        <TextField type="text" label="Ville" 
          style={{margin:"20px"}}
          name="ville"
          id="ville"
          onChange={(e) => setVille(e.target.value)}
          value={ville}
        />

        <TextField type="text" label="Code postal" 
          style={{margin:"20px"}}
          name="postal"
          id="postal"
          onChange={(e) => setPostal(e.target.value)}
          value={postal}
        />

        <TextField type="text" label="Pays" 
          style={{margin:"20px"}}
          name="pays"
          id="pays"
          onChange={(e) => setPays(e.target.value)}
          value={pays}
        />
      <br />
      <LoadingButton
        sx={{ marginTop: '20px', marginLeft: '20px' }}
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        onClick={handleUserUpdate}
      >
        Modifier mon profil 
      </LoadingButton>

    </Page>
  );
}
