"use client"
import React, { useState, useEffect, useContext, useRef } from "react";
import "./Chat.css";
import {
  Avatar,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Paper,
  Container,
  useTheme,
  useMediaQuery,
  Drawer,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AuthContext from "../../contexts/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { getConversation } from "../../service/conversationApi";
import { createMessage, createMessagePicture } from "../../service/messageApi";
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';

export default function Chat() {
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState([]);
    const [currentMessages, setCurrentMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [conversationKey, setConversationKey] = useState(0);
    const [deleteConversationId, setDeleteConversationId] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const { user, customerId } = useContext(AuthContext);

    const theme = useTheme();
    const isMatchSm = useMediaQuery(theme.breakpoints.down("md"));

    const [open, setOpen] = useState(false);
    const [messageDialog, setMessageDialog] = useState("");
    const [openConv, setOpenConv] = useState(false);

    const [images, setImages] = useState([]);
    const [imageUpload, setImageUpload] = useState(null);
    const listRef = useRef(null);
    const [prevMessagesLength, setPrevMessagesLength] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialoguePicture, setDialoguePicture] = useState(null);
    // const navigate = useNavigate();
    // setIsAuthenticated(authAPI.isAuthenticated);

    // if (!isAuthenticated) {
    //   navigate("/login");
    // }

    useEffect(() => {
        if (!customerId)
            return
        fetchConversations();
    }, [customerId]);

    useEffect(() => {
        setPrevMessagesLength(0);
        const id = setInterval(fetchConversations, 30000);
        return () => clearInterval(id);
    }, [conversationKey])

    useEffect(() => {
        if (message === '') {
        setImageUpload(null)
        setImages(null)
        }
    }, [message])

    useEffect(() => {
        if (listRef.current && currentMessages.length > prevMessagesLength) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
            setPrevMessagesLength(currentMessages.length); 
        }
    }, [currentMessages]);

    const handleClose = () => {
        setOpen(false);
    };

  // recup des conversations
    const fetchConversations = async () => {
        try {
            const data = await getConversation();
            setConversations(data);
            setCurrentConversation(conversationKey ? data[conversationKey] : data[0]);
            setCurrentMessages(
                conversationKey
                ? data[conversationKey]?.messages.sort((x, y) => x.id - y.id)
                : data[0]?.messages.sort((x, y) => x.id - y.id)
            );
            console.log(currentMessages)
        } catch (error) {
            console.error(error);
        }
    };

    // recuperations des conversations de l'user
    const handleConversation = (conversation, index) => {
        console.log(conversation)
        setCurrentConversation(conversation);
        setCurrentMessages(conversation.messages);
        setConversationKey(index);
    };

    const handleDelete = () => {
        handleClose();
    };

    const handleAlert = (action, conversationId) => {
        switch (action) {
        case "deleteConversation":
            setMessageDialog("Voulez vous Vraiment supprimer cette conversation ?");
            setDeleteConversationId(conversationId);
            setOpen(true);
            break;

        default:
            return;
        }
    };

    const handleMessage = async () => {
        if (message !== '') {
            setIsSending(true);
            let data = {
                conversation: `api/conversations/${currentConversation.id}`,
                content: message,
            };

            if (imageUpload) {
                const formData = new FormData();
                formData.append('picture', imageUpload);
                formData.append('conversation', currentConversation.id);
                await createMessagePicture(formData)
            } else {
                await createMessage(data)
            }
            setMessage("");
            setIsSending(false);
            setImageUpload(null)
            setImages(null)
            fetchConversations();
        }
    };

    const getPicture = (content) => {
        const imageUrl = `data:image/png;base64, ${content}`
        return imageUrl
    }

    const handlePicture = (event) => {
        const file = event.target.files[0];
        setImages(file)
        setImageUpload(file ? file : null);
        setMessage(`*${file.name}*`)
    }

    const deletePicture = () => {
        setImageUpload(null)
        setImages(null)
        setMessage('')
    }

    const handleOpenDialogue = (content) => {
        setDialoguePicture(getPicture(content))
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
        setDialoguePicture(null)
    }

    return (
        <div>
            <Container>
                {conversations.length > 0 ? (
                <Grid
                    container
                    component={Paper}
                    sx={{
                        width: isMatchSm ? "100%" : "70vw",
                        maxWidth: "100%",
                        height: "80vh",
                    }}
                >
                    {isMatchSm ? (
                        <>
                            <Button
                                variant="outlined"
                                onClick={() => setOpenConv(!openConv)}
                                startIcon={<QuestionAnswerIcon />}
                            />
                            <Drawer
                                setConversationKey    anchor="left"
                                open={openConv}
                                onClick={() => setOpenConv(!openConv)}
                            >
                                <Grid
                                    item
                                    md={4}
                                    sx={{
                                    borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    <List>
                                        <ListItem button key="">
                                            <ListItemIcon>
                                                <Avatar />
                                            </ListItemIcon>
                                            <ListItemText primary="vous"></ListItemText>
                                        </ListItem>
                                    </List>
                                    <Divider />
                                    <Grid item sx={{ padding: "5px" }} />
                                    <Divider />
                                    <List>
                                        {/* bouclage des annonces    setCurrentConversations(data)  */}

                                        {conversations.length > 0 &&
                                            conversations.map((conversation, index) => (
                                            <ListItem
                                                sx={{
                                                background:
                                                    currentConversation !== null &&
                                                    currentConversation === conversation
                                                    ? "#ccc"
                                                    : "#fff",
                                                }}
                                                button
                                                key={index}
                                                onClick={() =>
                                                    handleConversation(conversation, index)
                                                }
                                            >
                                                <ListItemIcon>
                                                    <Avatar
                                                        alt=""
                                                        src={
                                                        conversation.annonce.category
                                                            .isImageFrontHidden
                                                            ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${conversation.annonce.category.defaultImage}`
                                                            : `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conversation.annonce.filePath}`
                                                        }
                                                    />
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={conversation.annonce.category.name}
                                                   // secondary={conversation.annonce.customer.email}
                                                ></ListItemText>
                                                {/*<ListItemIcon sx={{ marginLeft: "20px" }}>
                                                    <DeleteIcon
                                                        onClick={() =>
                                                            handleAlert(
                                                                "deleteConversation",
                                                                conversation.id
                                                            )
                                                        }
                                                    />
                                                    </ListItemIcon>*/}
                                                    </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Drawer>
                        </>
                    ) : (
                        <Grid
                            item
                            md={4}
                            sx={{
                                borderRight: "1px solid #e0e0e0",
                            }}
                        >
                            <List>
                                <ListItem button key="">
                                    <ListItemIcon>
                                        <Avatar />
                                    </ListItemIcon>
                                    <ListItemText primary="vous"></ListItemText>
                                </ListItem>
                            </List>
                            <Divider />
                            {/* <Grid item sx={{ padding: "5px" }} /> */}
                            <Divider />
                            <List
                                sx={{
                                    width: "100%",
                                    height: "70vh",
                                    overflow: "auto",
                                }}
                            >
                            {/* bouclage des annonces    setCurrentConversations(data)  */}

                                {conversations.length > 0 &&
                                    conversations.map((conversation, index) => (
                                    <ListItem
                                        sx={{
                                            background:
                                                currentConversation !== null &&
                                                currentConversation === conversation
                                                ? "#ccc"
                                                : "#fff",
                                        }}
                                        button
                                        key={index}
                                        onClick={() => handleConversation(conversation, index)}
                                    >
                                        <ListItemIcon>
                                            <Avatar
                                                alt=""
                                                src={
                                                conversation.annonce.category.isImageFrontHidden
                                                    ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${conversation.annonce.category.defaultImage}`
                                                    : `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conversation.annonce.filePath}`
                                                }
                                            />
                                        </ListItemIcon>

                                        <ListItemText
                                            primary={conversation.annonce.category.name}
                                            // secondary={conversation.annonce.customer.email}
                                        />
                                        {/*<ListItemIcon sx={{ marginLeft: "20px" }}>
                                            <DeleteIcon
                                                onClick={() =>
                                                    handleAlert("deleteConversation", conversation.id)
                                                }
                                            />
                                            </ListItemIcon>*/}
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    )}
                    <Grid item xs={12} md={8}>
                        {/* boucle messages  */}

                        <List ref={listRef} className="list-message-chat" sx={{ height: isMatchSm ? "65vh" : "70vh", overflowY: "auto", scrollBehavior: 'smooth' }}>
                            {currentConversation.length > 0 && (
                                <ListItem key="0">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <ListItemText align="right" secondary=""></ListItemText>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )}

                            {currentMessages.length > 0 &&
                            currentMessages.map((message) => {
                                const messAuthId =
                                null !== message.customerAuthor
                                    ? message.customerAuthor.id
                                    : message.author.id;

                                const content = message.content;

                                console.log(message.customerAuthor === null)
                                return (
                                    <ListItem key={message.id}>
                                        <Grid container>
                                            <Grid item xs={12} className='message-container-chat'>
                                                { message.isPicture ? (
                                                    <>
                                                        <img
                                                            className={`${message.customerAuthor === null ? 'left' : 'right '} picture-chat`}
                                                            align={messAuthId === user.id ? 'left' : 'right '}
                                                            src={getPicture(message.content)}
                                                            onClick={() => {handleOpenDialogue(message.content)}}
                                                            alt="picture"
                                                            width={100}
                                                            height={100}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {message.isRead ? (
                                                            <>
                                                                <ListItemText
                                                                sx={{
                                                                    backgroundColor: "green",
                                                                    color: "white",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    window.location.replace(content);
                                                                }}
                                                                primary={content}
                                                                ></ListItemText>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ListItemText
                                                                    className={
                                                                        message.customerAuthor === null ? "left" : "right"
                                                                    }
                                                                    align={
                                                                        message.customerAuthor === null ? "left" : "right"
                                                                    }
                                                                    primary={message.content}
                                                                ></ListItemText>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ListItemText
                                                    align={message.customerAuthor === null ? "left" : "right"}
                                                    secondary={message.createdAt}
                                                ></ListItemText>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                );
                            })}
                            <Dialog
                                fullScreen
                                open={openDialog}
                                onClose={handleCloseDialog}
                                TransitionComponent={Slide}
                                TransitionProps={{ direction: 'up' }}
                                PaperProps={{
                                    style: {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    },
                                }}
                                >
                                <DialogActions
                                    sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    }}
                                >
                                    <IconButton
                                    onClick={handleCloseDialog}
                                    aria-label="close"
                                    sx={{ color: 'black' }}
                                    >
                                    <CloseIcon />
                                    </IconButton>
                                </DialogActions>
                                <DialogContent
                                    sx={{
                                    p: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color:"blue",
                                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                                    }}
                                >
                                    {dialoguePicture !== null && (
                                        <img
                                            className={`picture-dialogue-chat`}
                                            src={dialoguePicture}
                                            onClick={handleCloseDialog}
                                            alt="picture"
                                            width={100}
                                            height={100}
                                        />
                                    )}
                                </DialogContent>
                                </Dialog>
                            {/* <div ref={messagesEndRef}> </div> */}
                        </List>
                        <Divider />
                        <Grid container style={{ padding: "10px", justifyContent: "space-between", alignItems: "center" }}>
                            <Grid item xs={1.5} align="left" style={{ flexGrow: 1 }}>
                                { imageUpload === null ? (
                                    <Fab
                                        color="#ccc"
                                        aria-label="add" 
                                        className="fab-chat"
                                        component="label"
                                    >
                                        <ImageIcon />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            onChange={handlePicture}
                                        />
                                    </Fab>
                                ) : (
                                    <Fab
                                        color="#ccc"
                                        aria-label="add" 
                                        onClick={deletePicture}
                                        className="fab-chat"
                                    >
                                        <CloseIcon />
                                    </Fab>
                                )}
                            </Grid>
                            <Grid item xs={9} style={{ paddingRight: "5px" }}>
                                <TextField
                                    id="outlined-basic-email"
                                    label="Votre message ..."
                                    value={message}
                                    fullWidth
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1.5} align="right">
                                <Fab color="primary" aria-label="add" disabled={isSending} className="fab-chat">
                                    <SendIcon onClick={handleMessage} />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                ) : (
                    <>
                        <Typography>Votre messagerie est vide</Typography>
                    </>
                )}
            </Container>

        {/* <ActionDialogBox
            open={open}
            handleClose={handleClose}
            handleAction={handleDelete}
            message={messageDialog}
            elementActionId={deleteConversationId}
            actionButtonText="Supprimer la conversation"
        /> */}
        </div>
    );
}
