import jwtdecode from 'jwt-decode';
import axios from 'axios';
import Cookies from 'universal-cookie';

function authenticate(credentials) {
  const cookies = new Cookies(null, { path: '/' });

  const ApiUrl = process.env.REACT_APP_PUBLIC_API + '/userlogin';
  return axios
    .post(ApiUrl, credentials)
    .then((response) => {
      console.log(response)
      return response.data
      })
    .then(async (data) => {
      console.log(data)
      window.localStorage.setItem('authToken', data.token);
      cookies.set('authToken', data.token, {
        domain: '.sherlook.fr', 
        path: '/', 
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24)
      });

      axios.defaults.headers['Authorization'] = 'Bearer ' + data.token;

      await axios.get(process.env.REACT_APP_PUBLIC_API + '/me').then(responseTwo => {
        console.log('ok', responseTwo)
        window.localStorage.setItem('customerId', responseTwo.data.id);
      })

      return true;  
    });
}

export function RegisterUser(user) {
  return axios.post(process.env.REACT_APP_PUBLIC_API + '/users', user)
}

export function forgotPassword(user) {
  return axios.post(process.env.REACT_APP_PUBLIC_API + '/forgot-password', user)
}

function logout() {
  window.localStorage.removeItem('authToken');
  window.localStorage.removeItem('customerId');
  delete axios.defaults.headers['Authorization'];

}

function setAxiosToken(token) {
  axios.defaults.headers['Authorization'] = 'Bearer ' + token;
}

function setup() {
  const token = window.localStorage.getItem('authToken');
  if (token) {
    const { exp: expirationToken } = jwtdecode(token);

    if (expirationToken * 1000 > new Date().getTime()) {
      setAxiosToken(token);
    } else {
      //logout();
      return
    }
  } else {
    logout();
  }
}

function isAuthenticated() {
  const token = window.localStorage.getItem('authToken');
  if (token) {
    const { exp: expirationToken } = jwtdecode(token);

    if (expirationToken * 1000 > new Date().getTime()) {
      return true;
    }
    return false;
  }
  return false;
}

function getCustomerId() {
  const customerId = window.localStorage.getItem('customerId');
  if (customerId && isAuthenticated())
    return customerId
  return null
}

export default {
  setup,
  logout,
  isAuthenticated,
  authenticate,
  getCustomerId,
};
