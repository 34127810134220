import React, { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  Divider,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Chip,
  Grid,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { updateAnnonce, deleteAnnonce } from 'src/service/annonceApi';
// utils

// components
import Label from '../../../components/Label';
import InventoryIcon from '@mui/icons-material/Inventory';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { ProductImgStyle } from 'src/service/componentService';

// ----------------------------------------------------------------------
const ColorBox = ({ color }) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        width: '16px',
        height: '16px',
        borderRadius: '3px',
        margin: '2px',
      }}
    />
  );
};

// ----------------------------------------------------------------------

export default function ShopProductCard({ ads, handleClickOpenDialog, fetchFoundAds }) {
  const { id, title, filePath, status, annonceAttributes, category, stockage, date, contact, userExpedition } = ads;
  const frenchdate = new Date(date);
  const [secretOpen, setSecretOpen] = useState(false);
  const annonceDate = frenchdate.toLocaleString('fr-FR', { month: 'long', day: 'numeric', year: 'numeric' });
  const boxName = null !== stockage ? stockage.boxName : 'pas de stockage';
  const cover =
    filePath === null
      ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${category.defaultImage}`
      : `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${filePath}`;

  const [actionSentence, setActionSentence] = useState('');
  const [actionMessage, setActionMessage] = useState('');
  const [action, setAction] = useState('');
  const [handleOnAction, setHandleOnAction] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const blocAdress =
    userExpedition !== null ? (
      <>
        <Typography>
          {userExpedition.firstname} {userExpedition.lastname}
        </Typography>
        <Typography>{userExpedition.street}</Typography>
        <Typography>{userExpedition.lieudit}</Typography>
        <Typography>{userExpedition.batiment}</Typography>
        <Typography>{userExpedition.apptEtage}</Typography>
        <Typography>
          {userExpedition.cp} {userExpedition.ville}
        </Typography>
        <Typography>{userExpedition.country}</Typography>
      </>
    ) : (
      ''
    );

  const handleClickOpen = (action) => {
    switch (action) {
      case 'Supprimer':
        setActionSentence(`Voulez vous supprimer cet Objet : ${category.name} ${id}`);

        setAction('Supprimer');
        setHandleOnAction('delete');
        setOpenDialog(true);
        break;

      case 'send':
        setActionSentence(`Voulez vous déclarer cet objet comme envoyé ? : ${category.name} ${id}`);

        setAction('Oui Il a été envoyé');
        setHandleOnAction('send');
        setOpenDialog(true);
        break;

      default:
        return;
    }
  };

  const handleAction = () => {
    switch (handleOnAction) {
      case 'delete':
        handleDelete();
        setOpenDialog(false);
        break;
      case 'send': {
        handleIsSend();
        setOpenDialog(false);
      }
      default:
        return;
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    try {
      await deleteAnnonce(id);
      setHandleOnAction('');
      setAction('');
      setActionSentence('');
      setActionMessage('');

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleIsSend = async () => {
    const data = {
      status: 'ENVOYÉ',
    };
    try {
      await updateAnnonce(id, data);
      setHandleOnAction('');
      setAction('');
      setActionSentence('');
      setActionMessage('');
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Card>
        <Box sx={{ pt: '100%', position: 'relative' }} onClick={() => handleClickOpenDialog(ads)}>
          {status && (
            <Label
              variant="filled"
              color={(status === 'sale' && 'error') || 'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {status}
            </Label>
          )}
          <ProductImgStyle alt={title} src={cover} />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant="subtitle" noWrap>
              <Typography variant="h5"> {category.name} </Typography>
              <Chip label={id} color="primary" />
            </Typography>
          </Link>
          <Divider />

          <Stack>
            <Typography variant="subtitle1">
              <Typography component="span" variant="body1"></Typography>
            </Typography>
            <List sx={{ padding: 0, margin: 0 }}>
              <ListItem sx={{ padding: 0, margin: 0 }}>
                <ListItemIcon>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText primary={annonceDate} sx={{ padding: 0, margin: 0 }} />
              </ListItem>
            </List>
            {contact && (
              <List>
                <ListItem sx={{ padding: 0, margin: 0 }}>
                  <ListItemIcon>
                    <ContactPhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary={contact} sx={{ padding: 0, margin: 0 }} />
                </ListItem>
              </List>
            )}

            {/*status === 'TROUVÉ' && (
              <List>
                <ListItem sx={{ padding: 0, margin: 0 }}>
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary={boxName} sx={{ padding: 0, margin: 0 }} />
                </ListItem>
              </List>
            )*/}

            <Typography>Détails</Typography>
            {blocAdress}
            <Divider />
              <ListItem>{ads.ville}</ListItem>
            <Divider />

            {annonceAttributes
              .filter((att) => att.isSecret === false)
              .map((detail, index) => (
                <List key={index} sx={{ padding: 0, margin: 0 }}>
                  <ListItem sx={{ padding: 0, margin: 0 }}>
                    <ListItemText
                      className="details"
                      primary={detail.attribute}
                      secondary={
                        detail.uiType === 'colorcheckbox' ? <ColorBox color={detail.uiValue} /> : detail.attributeValue
                      }
                      sx={{ padding: 0, margin: 0 }}
                    />
                  </ListItem>
                  <Divider />
                </List>
              ))}

            {secretOpen ? (
              <KeyboardArrowDownIcon onClick={() => setSecretOpen(!secretOpen)} />
            ) : (
              <KeyboardArrowRightIcon onClick={() => setSecretOpen(!secretOpen)} />
            )}
            {secretOpen && (
              <>
                <Typography color="red">Détails Secrets</Typography>
                <Box>
                  <Divider />
                  {/* boucle sur les attributs */}

                  {annonceAttributes
                    .filter((att) => att.isSecret === true)
                    .map((secret, index) => (
                      <List key={index} sx={{ padding: 0, margin: 0 }}>
                        <ListItem sx={{ padding: 0, margin: 0 }}>
                          <ListItemText
                            className="details"
                            primary={secret.attribute}
                            secondary={secret.attributeValue}
                            sx={{ padding: 0, margin: 0 }}
                          />
                        </ListItem>
                      </List>
                    ))}

                </Box>
                <Divider />
                <Grid container display="flex" pt={2} justifyContent="center">
                  {/* <Grid>
                    <Button variant="outlined" startIcon={<EditIcon />}>
                      Modifier
                    </Button>
                  </Grid> */}
                  <Grid>
                    {status === 'PAYÉ' && (
                      <Button
                        sx={{
                          marginRight: '10px',
                        }}
                        variant="outlined"
                        color="success"
                        startIcon={<ForwardToInboxIcon />}
                        onClick={() => handleClickOpen('send')}
                      >
                        Envoyé !
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleClickOpen('Supprimer')}
                    >
                      Supprimer
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Stack>
        </Stack>
      </Card>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{actionSentence}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{actionMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDialog} autoFocus>
            ne rien faire
          </Button>
          <Button variant="contained" color="error" onClick={handleAction}>
            {action}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
