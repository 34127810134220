import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RegisterUser } from 'src/service/authAPI';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();

  const [error, setError] = useState("")
  const [showErrorPassword, setShowErrorPassword] = useState(false)
  const [showErrorEmail, setShowErrorEmail] = useState(false)

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Votre prénom est obligatoire'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Votre nom est obligatoire'),
    email: Yup.string().email('Votre adresse email n\'est pas valide.').required('Email obligatoire'),
    password: Yup.string().required('Le mot de passe doit comporter des minuscules, une majuscule, un chiffre, un caractère spécial et faire plus de 8 caractères.'),
  });

  function verifierCaracteresSpeciaux(chaine) {
    var majuscule = /[A-Z]/;
    var minuscule = /[a-z]/;
    var chiffre = /[0-9]/;
    var caractereSpecial = /[^A-Za-z0-9]/;

    if (chaine.length < 8) {
        return false;
    }

    return majuscule.test(chaine) && minuscule.test(chaine) && chiffre.test(chaine) && caractereSpecial.test(chaine);
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, { setSubmitting }) => {

      const body = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        password: values.password
      }

      if(verifierCaracteresSpeciaux(values.password)) {
        RegisterUser(body).then((res) => {
          navigate('/login?from=register', { replace: true });
        }).catch((err) => {
          console.log(err, 'erreur !')
            if (err.response.status === 422) {
                console.log(err.response.data.detail.data.detail)
                setError(err.response.data.detail)
                setShowErrorPassword(false)
                setShowErrorEmail(true)

                setSubmitting(false)
            }
        })
      } else {
        setShowErrorPassword(true)
        setSubmitting(false)
        setShowErrorEmail(false)
      }
    
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Votre prénom"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Votre nom"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Adresse email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Mot de passe"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          {showErrorPassword &&
            <p style={{color:"red"}}>Le mot de passe doit comporter des minuscules, une majuscule, un chiffre, un caractère spécial et faire plus de 8 caractères.</p>          
          }

          {showErrorEmail &&
            <p style={{color:"red"}}>Cet adresse email est déjà utilisé par un autre compte.</p>          
          }   

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Inscription
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
