import { useContext } from 'react';
import { Typography, TextField } from "@mui/material";
import React, { useEffect } from "react";
import AnnonceContext from '../../../../contexts/AnnonceContext';

import Upload from "./Forms/upload";

export default function Step3() {

  const { formAnnonce, setFormAnnonce } = useContext(AnnonceContext);

  return (
    <>
      <Typography variant="body1">
       Cliquer pour ajouter ou prendre une photo
      </Typography>

      <Upload />

      <Typography variant="body1">
       Cliquer pour ajouter ou prendre une photo
      </Typography>

      <br />
      {formAnnonce.status === "PERDU" ? (
        <>
           <Typography variant="body1">
                Décrivez ici les circonstances de la perte et/ou tout autre détail
                important pour vous, afin d’apporter un maximum de précisions à votre
                annonce.
            </Typography>
            <Typography variant="body1">
                Ex. Je pense avoir perdu mon objet en sortant du restaurant, place de la
                mairie à Rennes.
            </Typography>        
        </>
      ): (
        <>
            <Typography variant="body1">
                Afin d'apporter plus de détails à votre annonce, décrivez ici les circonstances dans lesquelles vous avez trouvé cet objet. 
            </Typography>
            <Typography variant="body1">
                <u>Exemple:</u> J'ai trouvé cet objet en sortant du restaurant, "chez Justine" place de la mairie à Rennes.
            </Typography>        
        </>
      )}
      <br />

      <TextField
                fullWidth
                id="outlined-multiline-flexible"
                label="Détails"
                multiline
                maxRows={4}
                value={formAnnonce ? formAnnonce.details : ""}
                onChange={(e) =>
                    setFormAnnonce({ ...formAnnonce, details: e.target.value })
                }
      />

    </>
  );
}
