import { Api } from '@mui/icons-material';
import axios from 'axios';

export const getCustomersAds = async (id, status = "") => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/users/${id}/annonces`;

    return axios.get(ApiUrl).then((response) => response.data);
}

export const getCustomerAdsCheckout = async () => {
    const ApiUrl = await axios.get(process.env.REACT_APP_PUBLIC_API + "/checkout/annonce").then((response => response.data));

    return ApiUrl
}