import React, { createContext, useContext, useEffect, useState } from 'react';
import CustomStepper from './FormComponents/CustomStepper';
import AnnonceContext from '../../../contexts/AnnonceContext';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { createAnnonce, updateAnnonceImage } from 'src/service/annonceApi';
import { notifySuccess, notifyOneErrorEmpty } from 'src/service/notificationService';
import { getCustomer } from 'src/service/customer';
import BasicModal from 'src/components/Modal/Modal';

const Publier = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formAnnonce, setFormAnnonce] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [formAttributes, setFormAttributes] = useState([]);
  const [imageUpload, setImageUpload] = useState();
  const [origin, setOrigin] = useState("TROUVÉ");
  const [status, setStatus] = useState("TROUVÉ");
  const [date, setDate] = useState(new Date().toISOString());
  const [partenaire, setPartenaire] = useState()
  const [partenaireName, setPartenaireName] = useState("")

  const [Loading, setLoading] = useState(true)
  const [modal, setModal] = useState(false)

  const [annonceFrom, setAnnonceFrom] = useState("")

  let navigate = useNavigate();

  useEffect(async () => {
    if(window.localStorage.getItem('annonceFrom')) {
      setAnnonceFrom(window.localStorage.getItem('annonceFrom'))
      console.log(window.localStorage.getItem('annonceFrom'))
      setModal(true)
      console.log(modal)
    }

    if(window.localStorage.getItem('customerIdFrom')) {
      setPartenaire(window.localStorage.getItem('customerIdFrom'))
      await getCustomer(window.localStorage.getItem('customerIdFrom')).then(res => {
        setPartenaireName(res.name)
        setFormAnnonce({
          ...formAnnonce,

          origin: 'PERDU',
          status: 'PERDU',
          ville: res.name,
          placeDescription: res.name +  ', France',
          annonceAttributes: formAttributes,
          date: date,
          professionalId: res.id
        });
      })

      setLoading(false)
    } else {
      setFormAnnonce({
        ...formAnnonce,

        origin: "TROUVÉ",
        status: "TROUVÉ",
        professionalId: null,
        annonceAttributes: formAttributes,
        date: date,
      });
      setLoading(false)
    }
  }, [formAttributes]);

  const checkStockage = () => {
    if (formAnnonce.stockage === undefined || formAnnonce.stockage === '')
      return false
    return true
  }

  const handleNotifySuccess = () => {
    navigate('/dashboard/products/trouves');
  }

  const handleSubmit = async () => {
    try {
      if(formAnnonce.ville === undefined) {
        notifyOneErrorEmpty('Le lieu est manquant.')
      } else {
        const response = await createAnnonce(formAnnonce);
        const annonceId = response.id;
        updateAnnonceImage(annonceId, imageUpload)
        notifySuccess('Annonce ajoutée avec succès', handleNotifySuccess)
      }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AnnonceContext.Provider
      value={{
        formAnnonce: formAnnonce,
        setFormAnnonce: setFormAnnonce,
        currentCategory: currentCategory,
        setCurrentCategory: setCurrentCategory,
        formAttributes: formAttributes,
        setFormAttributes: setFormAttributes,
        handleSubmit: handleSubmit,
        imageUpload: imageUpload,
        setImageUpload: setImageUpload,
        activeStep: activeStep,
        setActiveStep: setActiveStep,
        date: date,
        setDate: setDate,
        partenaire: setPartenaire,
      }}
    >
      <Page>
        {Loading ? (
          <p>Loading...</p>
        ) : (
          <CustomStepper />
        )}

        {modal &&
          <BasicModal id={annonceFrom} />
        }
        
      </Page>
    </AnnonceContext.Provider>
  );
};

export default Publier;
