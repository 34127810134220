import {useEffect, useState} from "react"
// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';

// sections
import { LoginForm } from '../sections/auth/login';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import { getCustomer } from "src/service/customer";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const [alert, setAlert] = useState(false)

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    
    if(queryParameters.get("from") === "register") {
      setAlert(true)
    }

    if(queryParameters.get("customerIdFrom")) {
      getCustomer(queryParameters.get("customerIdFrom")).then(res => {
        if(res.id) {
          window.localStorage.setItem('customerIdFrom', res.id);
        }
      })
    }

    if(queryParameters.get('annonceFrom')) {
      window.localStorage.setItem('annonceFrom', queryParameters.get('annonceFrom'))
    }
  }, [])

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
        {mdUp && (
          <a href="https://sherlook.fr">
            <ArrowBackIosIcon className={`iconMobileNavbarBack`}/>
          </a> 
        )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5}}>
             Bienvenue ! 
            </Typography>
            <a href="https://sherlook.fr">
              <img src="/static/logo.svg" alt="login" />
            </a>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
          {alert &&
            <Alert severity="success">
              <AlertTitle>Inscription réussi !</AlertTitle>
              Vous allez reçevoir un email de confirmation pour vous connecter.
            </Alert>
          }  
          <p>Vous n'avez pas de compte chez sherlook ? <a href="/register">Inscrivez-vous !</a></p>
            <Typography variant="h4" gutterBottom>
             Connectez vous avec vos identifiants
            </Typography>

          

           

            <LoginForm />
            
          
            
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
