import React, { useState } from 'react';
import { Button, Modal, TextField, Box } from '@mui/material';
import { createConversation } from 'src/service/conversationApi';
import { notifySuccess, notifyOneErrorEmpty } from 'src/service/notificationService';

const BasicModal = ({id}) => {
  const [open, setOpen] = useState(true);
  const [message, setMessage] = useState('');

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setMessage('');
    window.localStorage.removeItem('annonceFrom')
  };

  const handleMessageSend = () => {
    if(message.length > 0) {
        createConversation({
            "annonce": `api/annonces/${id}`,
            "messages": [
              {
                "content": message
              }
            ],
            "isPending": true,
            "isPicture": 0
          }).then(res => {
            notifySuccess("Message envoyé à l'annonceur avec succès !")
            handleClose()
          })
    } else {
        notifyOneErrorEmpty("Merci d'écrire votre message.")
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 2
          }}
        >
          <h5 id="modal-modal-title">Envoyer un message à l'annonceur. Annonce {id}.</h5>
          <TextField
            label="Message"
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            maxRows={10}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button onClick={handleMessageSend} variant="contained" color="primary">
            Envoyer
          </Button>
          <Button onClick={handleClose} sx={{ ml: 2 }}>
            Annuler
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
