import React, { useContext, useEffect, useState } from 'react';
import AnnonceContext from '../../../../contexts/AnnonceContext';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Autocomplete
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DaySelector from './Forms/DaySelector';
import cities from "../../../../data/cities.json"

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import GoogleMaps from './Forms/AutoComplete';
import ClearIcon from '@mui/icons-material/Clear';

export default function Step1() {
  const { formAnnonce, setFormAnnonce, date, setDate, location, setLocation, status, origin } = useContext(AnnonceContext);

  /*const [customers, setCustomers] = useState([
    { label: "demo" },
    { label: "Hotel 1" },
    { label: "Hotel 2" },
  ]);*/

  const [valuePartenaire, setValuePartenaire] = useState("")

  useEffect(() => {
    setValuePartenaire(formAnnonce.ville)
  })

  const handleChangeDate = (newDate) => {
    setDate(newDate);
    setFormAnnonce({ ...formAnnonce, date: newDate.toISOString() });
  };

  const handleStatus = (e) => {
    if(e.target.value == "PERDU") {
      window.localStorage.setItem('statusBug', 'PERDU')
    } else {
      window.localStorage.removeItem('statusBug')
    }
    setFormAnnonce({ ...formAnnonce, status: e.target.value, origin: e.target.value });
  };

  const handleLocation = (inputValue) => {
    setFormAnnonce({...formAnnonce, ville: inputValue, placeDescription: inputValue})
  }

  const changeLocation = () => {
    window.localStorage.removeItem("customerIdFrom")
    window.location.reload()
  }

  return (
    <>      
      <Stack spacing={2}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="status-radio-buttons-group"
            name="status-radio-buttons-group"
            value={formAnnonce.status}
            onChange={handleStatus}
          >
            {!formAnnonce.professionalId ? (
              <>
                <FormControlLabel
                  value="TROUVÉ"
                  control={<Radio checkedIcon={<SentimentSatisfiedAltIcon />} />}
                  label="Trouvé"
                />
                <FormControlLabel
                  value="PERDU"
                  control={<Radio checkedIcon={<SentimentSatisfiedAltIcon />} />}
                  label="Perdu"
                />
              </>
            ) : (
              <FormControlLabel
              value="PERDU"
              control={<Radio checkedIcon={<SentimentSatisfiedAltIcon />} />}
              label="Perdu"
            />
            )}
          </RadioGroup>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Quand ?"
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={(newDate) => {
              handleChangeDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <DaySelector setDate={setDate} date={date} />

        {!formAnnonce.professionalId ? (
          <GoogleMaps updateLocation={handleLocation} />
        ) : (
          <>
            <p>Lieu de la perte: {valuePartenaire} <ClearIcon onClick={changeLocation} sx={{cursor:"pointer"}} /></p>
            
          </>
        )}
      </Stack>
    </>
  );
}