import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Chat from 'src/components/Chat/Chat';
import Adetails from './Adetails';
import { styled } from '@mui/material/styles';
import UpdateAds from './UpdateAds';
import PropTypes from 'prop-types';
import AdsTracking from 'src/components/AdsTracking/AdsTracking';
import PaiementTracking from 'src/components/PaiementTracking/PaiementTracking';
import axios from "axios";
import ProductCardMatching from './ProductCardMatching';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function AnnonceDetails({ selectedAnnonce, setSelectedAnnonce, categories, updateUserAds }) {
  const [value, setValue] = useState(0);
  const [selectedAnnonceStatus, setSelectedAnnonceStatus] = useState(selectedAnnonce.status);

  const [matchingData, setMatchingData] = useState(null);
  const [Loading, setLoading] = useState(true)

  function formatStringForHTTPRequest(inputString) {
    let stringWithoutSpaces = inputString.replace(/ /g, "%20");
    
    let stringWithoutAccents = stringWithoutSpaces.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
    return stringWithoutAccents;
  }

  useEffect(async () => {
    let annonceCategoryId = selectedAnnonce.category.id
    let annonceAttributes = selectedAnnonce.annonceAttributes

    let url = ""
    annonceAttributes.map(doc => {
      url += "&annonceAttributes.attribute%5B%5D=" + formatStringForHTTPRequest(doc.attribute)
    })

    annonceAttributes.map(doc => {
      url += "&annonceAttributes.attributeValue%5B%5D=" + formatStringForHTTPRequest(doc.attributeValue)
    })

    url += "&origin=TROUVE&category.id=" + annonceCategoryId + "&status=TROUVE&date%5Bafter%5D=2023-12-01"

    console.log(url)

    await axios.get('https://api.sherlook.fr/api/annonces?' + url.slice(1)).then(res => {
      setMatchingData(res.data)
    })
    setLoading(false)
    console.log(matchingData)

  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Adetails annonce={selectedAnnonce} />
        </Grid>

        <Grid item xs={12} md={6} sx={{alignItems: "center", display: "grid"}}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{margin: "50px auto 0 auto"}} variant="fullWidth">
              <Tab label="Matching" {...a11yProps(0)} />              
              <Tab label="Modifier" {...a11yProps(1)} />
              
              <Tab label="Suivi de livraison" {...a11yProps(3)} />

          </Tabs>
          <Stack spacing={2}>
            <CustomTabPanel value={value} index={0}>
                {matchingData === null ? (
                  selectedAnnonceStatus === "PERDU" ? (
                    <>
                      {Loading ? (
                      <p>Matching</p>
                      ):(
                        <ProductCardMatching annonces={matchingData} />
                      )}
                    </>
                  ) : (
                    <>
                      <p>Le matching n'est pas disponible pour cette annonce.</p>
                    </>
                  )
                ) : (
                  <p>Aucune annonce correspndante.</p>
                )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <UpdateAds selectedAnnonce={selectedAnnonce} setSelectedAnnonce={setSelectedAnnonce} categories={categories} updateUserAds={updateUserAds}/>
            </CustomTabPanel>
            {selectedAnnonceStatus !== "TROUVÉ" && (
              <>
                <CustomTabPanel value={value} index={3}>
                  <AdsTracking annonce={selectedAnnonce} />
                </CustomTabPanel>
              </>
            )}
          </Stack>
        </Grid>

      </Grid>
    </>
  );
}
